import React from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import { Contact } from "../components/Contact";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const FeedbackPage = () => (
  <Page page="Anna palautetta">
    <Row>
      <Col>
        <Title>Anna palautetta</Title>

        <Content>
          Voit antaa palautetta sähköpostitse osoitteeseen <Contact /> tai
          ottamalla yhteyttä suoraan kaupunkikohtaisiin palautekanaviin:
        </Content>

        <Content as="ul">
          <li>
            <a href="https://www.hel.fi/helsinki/fi/kaupunki-ja-hallinto/osallistu-ja-vaikuta/palaute">
              Helsinki
            </a>
          </li>
          <li>
            <a href="https://e-asiointi.lahti.fi/eFeedback/">Lahti</a>
          </li>
          <li>
            <a href="https://e-kartta.ouka.fi/efeedback">Oulu</a>
          </li>
          <li>
            <a href="https://palaute.kuopio.fi/fi/etusivu">Kuopio</a>
          </li>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default FeedbackPage;
